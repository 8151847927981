import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import LanguageSelector from './components/LanguageSelector';
import './i18n';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
        <img src="/battito_logo.png" alt="Budgetino Logo" style={{ height: '50px' }} />
          <LanguageSelector />
        </div>
        <Routes>
          <Route path="/login" element={loggedIn ? <Navigate to="/dashboard" /> : <Login onLogin={handleLogin} />} />
          <Route path="/dashboard" element={loggedIn ? <Dashboard /> : <Navigate to="/login" />} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
