import React from 'react';
import { useTranslation } from 'react-i18next';
import Flag from 'react-flagkit';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <Flag country="GB" onClick={() => changeLanguage('en')} style={{ cursor: 'pointer', marginRight: '10px' }} />
      <Flag country="RS" onClick={() => changeLanguage('sr')} style={{ cursor: 'pointer' }} />
    </div>
  );
};

export default LanguageSelector;
