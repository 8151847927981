import React from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Login = ({ onLogin }) => {
  const { t } = useTranslation();

  const handleLogin = async () => {
    const emailAddress = document.getElementById('username').value;  // Change 'username' to 'emailAddress'
    const password = document.getElementById('password').value;

    try {
      const response = await fetch('https://api.battito.rs/webapp/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emailAddress, password }),  // Update to match required format
      });

      if (response.ok) {
        const data = await response.json();

        // Store the data in localStorage for future use
        localStorage.setItem('userData', JSON.stringify(data));

        // Call the onLogin function if authentication is successful
        onLogin();
      } else {
        alert('Invalid credentials or authentication failed.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        {t('login.title')}
      </Typography>
      <TextField
        id="username"
        label={t('login.username')}  // Keep this label as "Username" or update to "Email" if needed
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <TextField
        id="password"
        label={t('login.password')}
        type="password"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleLogin}
        fullWidth
      >
        {t('login.button')}
      </Button>
    </Container>
  );
};

export default Login;
